const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');
const { Image } = require('nordic/image');

const ICON_ID = 'rep_seller_delivery_time_good_v2';
const namespace = 'ui-pdp-icon ui-pdp-icon--time-positive';

const IconTimePositiveV2 = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image alt="" src="time-positive-v2.svg" />
  </div>
);

IconTimePositiveV2.propTypes = {
  className: string,
};

IconTimePositiveV2.defaultProps = {
  className: null,
};

module.exports = React.memo(IconTimePositiveV2);
exports = module.exports;
exports.ICON_ID = ICON_ID;
